import { ReactComponent as Lamp } from '../../assets/lamp.svg';
import { ReactComponent as Brain } from '../../assets/brain.svg';
import { ReactComponent as Face } from '../../assets/face.svg';
import styles from './help.module.scss';
import classNames from 'classnames';
import { Button } from 'shared/ui';
import React from 'react';

const content = [
  {
    icon: Lamp,
    text: `Загадано некоторое слово. У вас есть неограниченное количество попыток, чтобы его отгадать. После ввода слова вы увидите его позицию в рейтинге. Загаданное слово будет под номером 1`,
  },
  {
    icon: Brain,
    text: `Игра работает по принципу "холодно-горячо" с участием искусственного интеллекта`,
  },
  {
    icon: Face,
    text: `Если хотите поделиться результатом с друзьями, убедитесь, что скрыли свои слова с помощью значка глаза в верхнем правом углу`,
  },
];

interface HelpProps {
  onPlay: () => void;
  comment: string;
}

export const Help = ({ onPlay, comment }: HelpProps) => {
  return (
    <>
      <h3 className={styles.title}>Как играть</h3>
      <ul className={styles.list}>
        <li className={classNames(styles.text, styles.centered)}>
          {comment && (
            <>
              <b>{comment}</b>
              <br />
            </>
          )}
          Хочешь задать свое слово? Стань подписчиком в бусти! Или участвуй в розыгрышах в нашем тг канале <br />{' '}
          <a className={styles.text} target="_blank" href="https://t.me/guesswordcom" rel="noreferrer">
            https://t.me/guesswordcom
          </a>
        </li>

        {content.map((item, index) => (
          <li className={styles.item} key={index}>
            <item.icon />
            <p className={styles.text}>{item.text}</p>
          </li>
        ))}
      </ul>
      <Button onClick={onPlay}>Играть!</Button>
    </>
  );
};
