import { useCallback, useState } from 'react';
import { postRoomGuess } from 'entities/rooms/api';
import { mutate } from 'swr';
import { routes } from 'shared/api';

interface UseHandlePostGuessParams {
  roomSlug: string;
}

export const useHandlePostGuess = ({ roomSlug }: UseHandlePostGuessParams) => {
  const [isValidating, setIsValidating] = useState(false);

  const handlePostGuess = useCallback(
    async (word: string, language: string) => {
      setIsValidating(true);
      try {
        const result = await postRoomGuess(roomSlug, word.toLowerCase(), language);
        await mutate(routes.roomGuesses(roomSlug));
        return result;
      } finally {
        setIsValidating(false);
      }
    },
    [roomSlug],
  );

  return { handlePostGuess, isValidating };
};
