import { ReactComponent as Menu } from '../../assets/menu.svg';
import { ReactComponent as EyeOpen } from '../../assets/eye-open.svg';
import { ReactComponent as EyeClosed } from '../../assets/eye-closed.svg';
import { ReactComponent as Share } from '../../assets/share.svg';

import styles from './header.module.scss';
import { useParams } from 'react-router-dom';
import { useRoom } from 'entities/rooms/api';
import { useState } from 'react';
import { useIsDesktop } from 'shared/lib';
import { truncateString } from 'shared/lib';
import React from 'react';

interface HeaderProps {
  onEyeClick: () => void;
  onMenuClick?: () => void;
  onShareClick: () => void;
  background?: string;
  attemptCount?: number;
}

export const Header = ({ onEyeClick, onMenuClick, onShareClick, background, attemptCount = 0 }: HeaderProps) => {
  const { slug } = useParams<{ slug: string }>();
  const { matches: isDesktop } = useIsDesktop();
  const { data: room } = useRoom({ slug: slug ? slug : `main` });
  const [isBlurred, setBlurred] = useState(false);

  const menuColor = isDesktop ? 'black' : 'white';
  const handleEyeClick = () => {
    setBlurred(!isBlurred);
    onEyeClick();
  };

  return (
    <header className={styles.wrapper} style={{ background: background }}>
      <Menu className={styles.icon} onClick={onMenuClick} fill={menuColor} />
      <div className={styles.heading}>
        <h2 className={styles.title}>{truncateString(room?.name, 13) || `Отгадай слово`} </h2>
        <p className={styles.subtitle}>попыток: {attemptCount}</p>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {isBlurred ? (
          <EyeClosed onClick={handleEyeClick} className={styles.icon} />
        ) : (
          <EyeOpen onClick={handleEyeClick} className={styles.icon} />
        )}
        <Share onClick={onShareClick} className={styles.icon} />
      </div>
    </header>
  );
};
