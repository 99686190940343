import styles from './login-form.module.scss';
import { Button, Input } from 'shared/ui';
import { Link } from 'react-router-dom';
import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';

interface LoginFormProps {
  onSubmit: (login: string, password: string) => void;
  isLoading?: boolean;
  title: string;
  submitText: ReactNode;
  suffixText: ReactNode;
  suffixLink: string;
  suffixLinkText: string;
  error?: ReactNode;
}

export const LoginForm = ({ onSubmit, suffixText, suffixLinkText, suffixLink, title, isLoading, error }: LoginFormProps) => {
  const [login, setLogin] = useState(``);
  const [password, setPassword] = useState(``);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === `Enter`) {
        onSubmit(login, password);
      }
    },
    [login, onSubmit, password],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.sheet}>
        <h2 className={styles.title}>{title}</h2>
        <Input placeholder="Логин" className={styles.input} value={login} onChange={setLogin} onKeyDown={handleKeyDown} />
        <Input placeholder="Пароль" className={styles.input} password value={password} onChange={setPassword} onKeyDown={handleKeyDown} />
        <Button className={styles.button} onClick={() => onSubmit(login, password)}>
          Далее
        </Button>
        <p className={classNames(styles.loader, !isLoading && !error && styles['loader-hidden'])}>{error ? error : `Секунду...`}</p>
      </div>
      <p className={styles.suffix}>
        {suffixText}{' '}
        <Link to={suffixLink} className={styles.link}>
          {suffixLinkText}
        </Link>
      </p>
    </div>
  );
};
