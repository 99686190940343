import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';

interface ButtonProps {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  textColor?: string;
  backgroundColor?: string;
}

export const Button = ({ children, onClick, className, disabled = false, textColor = '', backgroundColor = '' }: ButtonProps) => {
  const buttonStyle = {
    color: textColor,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      className={classNames(styles.button, className, disabled && styles.disabledButtom)}
      onClick={onClick}
      disabled={disabled}
      style={textColor || backgroundColor ? buttonStyle : {}}
    >
      {children}
    </button>
  );
};
