import { useUserAuth } from 'features/user-auth-context/lib';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Path } from 'shared/config';

export const UnauthorizedLayout = () => {
  const { isAuthorized, isLoading } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthorized) {
      navigate(Path.Guess, { replace: true });
    }
  }, [isAuthorized, isLoading, navigate]);

  if (isLoading || isAuthorized) {
    return null;
  }

  return <Outlet />;
};
