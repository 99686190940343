import React, { ReactNode } from 'react';

import styles from './progress.module.scss';
import classNames from 'classnames';

interface ProgressProps {
  label: ReactNode;
  order: number;
  isHighlighted?: boolean;
  className?: string;
  isBlurred?: boolean;
}

const getColorClassName = (order: number) => {
  if (order < 200) {
    return styles.green;
  }
  if (order < 1000) {
    return styles.yellow;
  }
  return styles.red;
};

const MAX_RATING = 2000;

const getPercentage = (order: number) => {
  return Math.max(0, Math.min(100, ((MAX_RATING - order) / MAX_RATING) * 100));
};

export const Progress = ({ label, order, isHighlighted, className, isBlurred }: ProgressProps) => {
  const percentage = getPercentage(order);

  return (
    <div className={classNames(styles.wrapper, isHighlighted && styles.bordered, className)}>
      <span
        style={{
          width: `${percentage}%`,
        }}
        className={classNames(styles.progress, getColorClassName(order))}
      />
      <p
        className={styles.label}
        style={{
          color: isBlurred ? `transparent` : undefined,
        }}
      >
        {label}
      </p>
      <p className={styles.value}>{order}</p>
    </div>
  );
};
