import useSWR from 'swr';

import { api, routes } from 'shared/api';
import { useUserAuth } from 'features/user-auth-context/lib';
import type { Definitions, Paths } from 'backend';

export const postRoomGuess = async (roomSlug: string, word: string, language: string): Promise<Definitions.SecretWordGuess> => {
  return (await api.post(routes.roomCreateGuess(roomSlug, language), { word })).data;
};

export const useRoomHistory = (slug: string | null) => {
  const { isLoading } = useUserAuth();
  return useSWR<Definitions.SecretWordGuess[]>(slug && !isLoading ? routes.roomGuesses(slug) : null);
};

export const getRoomHistory = async (slug: string | null) => {
  return await api.get<Definitions.SecretWordGuess[]>(routes.roomGuesses(slug || 'main'));
};

export const postRoom = async (body: Paths.RoomsCreate.Parameters.Data) => {
  return (await api.post<Paths.RoomsCreate.Responses.$201>(routes.rooms, body)).data;
};

export const deleteRoom = async (slug: string) => {
  return await api.delete<Paths.RoomsDelete.Responses.$204>(routes.room(slug));
};

export const useUser = () => {
  const { isLoading } = useUserAuth();
  return useSWR<Paths.AuthUsersMeRead.Responses.$200>(!isLoading ? routes.me() : null);
};

export const useRoom = (params: Paths.Rooms$Slug.PathParameters | null) => {
  const { isLoading } = useUserAuth();
  return useSWR<Paths.RoomsRead.Responses.$200>(params && !isLoading ? routes.room(params.slug) : null);
};

export const useRoomOwner = (params: Paths.Rooms$Slug.PathParameters | null) => {
  const { isLoading } = useUserAuth();
  return useSWR<Paths.RoomsOwnRead.Responses.$200>(params && !isLoading ? `${routes.roomOwner(params.slug)}` : null);
};
