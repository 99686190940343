import '../../styles/colors.scss';
import '../../styles/app.scss';

import React, { useState } from 'react';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Guess } from 'pages/guess/ui';
import { Path } from 'shared/config';
import { BaseLayout } from 'shared/layouts/base-layout/ui';
import { AuthorizedLayout } from 'shared/layouts/authorized-layout/ui';
import { UnauthorizedLayout } from 'shared/layouts/unauthorized-layout/ui';
import { Login } from 'pages/login/ui';
import { Register } from 'pages/register/ui';
import { RoomsNew } from 'pages/rooms-new/ui';
import { Room } from 'pages/room/ui';
import { RoomsList } from 'pages/roomslist/ui';
import { NotFound } from 'pages/not-found/ui';

import { api } from 'shared/api';
import { AxiosError } from 'axios';
import { Works } from 'pages/engineering-works/ui';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<BaseLayout />}>
        <Route path={Path.Guess} element={<Guess />} />
        <Route path={Path.GuessWithSlug} element={<Guess />} />
      </Route>
      <Route element={<AuthorizedLayout />}>
        <Route path={Path.RoomsNew} element={<RoomsNew />} />
        <Route path={Path.RoomsList} element={<RoomsList />} />
        <Route path={Path.Room} element={<Room />} />
      </Route>
      <Route element={<UnauthorizedLayout />}>
        <Route path={Path.Login} element={<Login />} />
        <Route path={Path.Register} element={<Register />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path={Path.NotFound} element={<NotFound />} />
    </Route>,
  ),
);

export const App = () => {
  const [error, setError] = useState(false);

  api.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response && error.response.status >= 500) {
      // Handle 500+ errors globally
      setError(true);
      console.error('404 error occurred:', error.message);
    }

    return Promise.reject(error);
  });

  return <>{error ? <Works /> : <RouterProvider router={router} />}</>;
};
