import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRoom } from 'entities/rooms/api';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { Progress } from '../progress';

import styles from './guess.module.scss';

import Confetti from 'react-confetti';

import { ReactComponent as Cross } from '../../assets/cross.svg';

import { useHandlePostGuess } from '../../lib';
import { Help } from '../help';
import { Modal } from 'shared/ui/modal/ui/modal';
import { ModalContent } from '../modal-content';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useIsDesktop } from 'shared/lib';
import { Path } from 'shared/config';
import { api } from 'shared/api';
import { postAuthRefresh } from 'entities/auth/api';
import { CountdownTimer } from 'shared/ui/countdown-timer';
import type { Definitions } from 'backend';

const declareNumber = (number: number, words: [string, string, string]) => {
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]];
};

export const Guess = () => {
  const { slug } = useParams<{ slug: string }>();
  const [guessWord, setGuessWord] = useState('');
  const [previousGuessWord, setPreviousGuessWord] = useState<string[]>([]);
  const { data: room, error: roomError } = useRoom({ slug: slug || `main` });
  const { handlePostGuess, isValidating } = useHandlePostGuess({
    roomSlug: slug || `main`,
  });
  const [roomGuesses, setRoomGuesses] = useState<Definitions.SecretWordGuess[]>([]);
  const [lastGuessedWord, setLastGuessedWord] = useState<string>();
  const [lastGuessedWrongWord, setLastGuessedWrongWord] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAltModalVisible, setIsAltModalVisible] = useState(false);
  const [isLastWordNotExist, setIsLastWordNotExist] = useState(false);
  const [isUnsupportedLanguage, setIsUnsupportedLanguage] = useState(false);
  const { matches: isDesktop } = useIsDesktop();
  const [telegramButtonMode, setTelegramButtonMode] = useState<boolean>(false);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const WebApp = useWebApp();

  const lastGuessedWordObject = Array.isArray(roomGuesses) ? roomGuesses?.find((guess) => guess.word === lastGuessedWord) : undefined;

  const { setOnShareClick, isHelpVisible, setIsHelpVisible, setAttemptCount, isBlurred, language } = useOutletContext<{
    setOnShareClick: (callback: () => void) => void;
    isHelpVisible: boolean;
    setIsHelpVisible: (arg: boolean) => void;
    setAttemptCount: (arg: number) => void;
    isBlurred: boolean;
    language: string;
  }>();

  useEffect(() => {
    WebApp.MainButton.setText('Сделать попытку').show();
  }, [slug]);

  useEffect(() => {
    setIsUnsupportedLanguage(false);
  }, [language]);

  useEffect(() => {
    const handleClick = () => {
      if (WebApp.MainButton.text === 'Сделать попытку') {
        handleInputEnter({ preventDefault: () => {} });
      } else {
        const data = JSON.stringify({ finished: telegramButtonMode });
        WebApp.sendData(data);
        WebApp.close();
      }
    };
    WebApp.MainButton.onClick(handleClick);

    return () => {
      WebApp.MainButton.offClick(handleClick);
    };
  }, [telegramButtonMode]);

  useEffect(() => {
    postAuthRefresh({
      refresh: localStorage.getItem(`refresh`) as string,
    }).finally(() => {
      /* global process */
      fetch(process.env.REACT_APP_BACKEND_URL + `/rooms/${slug || 'main'}/history/?language=${language}`, {
        headers: {
          Authorization: api.defaults.headers.common.Authorization as string,
        },
        credentials: 'include',
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setRoomGuesses(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          },
        );
    });
  }, [navigate, slug, language]);

  const roomGuessesSorted = useMemo(() => {
    if (Array.isArray(roomGuesses)) {
      return [...roomGuesses].sort((a, b) => a.order - b.order);
    } else {
      return roomGuesses;
    }
  }, [roomGuesses]);

  useEffect(() => {
    setAttemptCount(roomGuesses.length);
    if (roomGuesses && roomGuesses.length !== 0) {
      setIsHelpVisible(false);
    }
  }, [roomGuesses, setAttemptCount, setIsHelpVisible]);

  useEffect(() => {
    if (roomError?.response?.status === 404) {
      navigate(Path.NotFound);
    }
  }, [navigate, roomError]);

  const handleInputEnter = async (event: any) => {
    event.preventDefault();

    const guessInput = document.getElementById('main-input') as HTMLInputElement;

    let word = guessInput.value;
    word = word.trim();
    word = word.toLocaleLowerCase();
    guessInput.value = '';

    if (word) {
      const index = previousGuessWord.indexOf(word);
      if (index !== -1) {
        previousGuessWord.splice(index, 1);
      }
      setPreviousGuessWord([...previousGuessWord, word]);
    }

    setGuessWord('');

    setIsLastWordNotExist(false);
    setLastGuessedWord(undefined);

    try {
      setIsUnsupportedLanguage(false);
      const newWord = await handlePostGuess(word.toLowerCase(), language);
      if (!newWord.already_guessed) {
        setRoomGuesses([...roomGuesses, newWord]);
      }
      setLastGuessedWord(word.toLowerCase());
    } catch (e: any) {
      if (e?.response?.data?.error_code === 'word_not_found') {
        setIsLastWordNotExist(true);
        setLastGuessedWrongWord(word.toLowerCase());
      } else if (e?.response?.data?.error_code === 'language_is_not_supported') {
        setIsUnsupportedLanguage(true);
      } else {
        console.error(e);
      }
    }

    return false;
  };

  const lastWordHandle = (event: { key: string; preventDefault: () => void }) => {
    const length = previousGuessWord.length;

    if (length === 0 || !inputRef.current) return;

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      inputRef.current.value = previousGuessWord[length - 1];
      setGuessWord(inputRef.current.value);
      previousGuessWord.unshift(previousGuessWord.pop() as string);
      inputRef.current.selectionEnd = 100;
    } else if (event.key === 'ArrowDown') {
      previousGuessWord.push(previousGuessWord.shift() as string);
      inputRef.current.value = previousGuessWord[0];
      setGuessWord(inputRef.current.value);
    }
  };

  const handlePlay = useCallback(() => {
    setIsHelpVisible(false);
    inputRef.current?.focus();
  }, [setIsHelpVisible]);

  useEffect(() => {
    if (lastGuessedWordObject?.order === 1) {
      setIsModalVisible(true);
      WebApp.MainButton.setText('Я угадал слово!').show();
      setTelegramButtonMode(true);
    }
  }, [lastGuessedWordObject?.order]);

  useEffect(() => {
    setOnShareClick(() => () => setIsAltModalVisible(true));
  }, [setOnShareClick]);

  const bottomSheetContent = (
    <>
      {lastGuessedWordObject && !isHelpVisible && (
        <Progress
          isHighlighted
          isBlurred={isBlurred || isModalVisible || isAltModalVisible}
          label={lastGuessedWordObject.word}
          order={lastGuessedWordObject.order}
          className={styles['last-guessed-word']}
        />
      )}
      {isValidating && <p className={styles.loader}>Секунду....</p>}
      {!isValidating && isLastWordNotExist && (
        <p className={styles.loader}>
          Я не знаю слова <b>{lastGuessedWrongWord}</b> :(
        </p>
      )}
      {!isValidating && isUnsupportedLanguage && (
        <p className={styles.loader}>
          Это комната не поддерживает <b>{language == 'EN' ? 'английский' : language == 'RU' && 'русский'} язык</b> :(
        </p>
      )}
      {!roomGuesses?.length || isHelpVisible ? (
        <Help onPlay={handlePlay} comment={room?.comment || ''} />
      ) : (
        <div className={styles.words}>
          {roomGuessesSorted?.map((roomHistoryItem: any) => (
            <div key={roomHistoryItem.word}>
              <Progress
                isBlurred={isBlurred || isModalVisible || isAltModalVisible}
                key={roomHistoryItem.id}
                isHighlighted={lastGuessedWord === roomHistoryItem.word}
                label={roomHistoryItem.word}
                order={roomHistoryItem.order}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );

  const [confettiWidth, setConfettiWidth] = useState<number>(350);

  useEffect(() => {
    if (modalRef && modalRef.current) {
      setConfettiWidth((modalRef.current as HTMLElement).scrollWidth + 28 || 350);
    }
  }, [isModalVisible]);

  return (
    <>
      <div className={styles.wrapper}>
        <form onSubmit={handleInputEnter} className={styles['input-form']} style={{ background: !room ? `#6688cc` : room?.theme }}>
          <div>
            <input
              className={styles.input}
              onFocus={handlePlay}
              onChange={(event) => setGuessWord(event.target.value)}
              onKeyDown={lastWordHandle}
              id="main-input"
              placeholder="Введите слово"
              ref={inputRef}
              autoComplete="off"
            />
            {!!guessWord && (
              <Cross
                className={styles['clear-icon']}
                onClick={() => {
                  const guessInput = document.getElementById('main-input') as HTMLInputElement;
                  setGuessWord(``);
                  guessInput.value = '';
                  inputRef.current?.focus();
                }}
              />
            )}
          </div>
        </form>

        {isDesktop ? (
          <div className={styles['pc-words-wrapper']}>{bottomSheetContent}</div>
        ) : (
          <div className={styles['mobile-words-wrapper']}>{bottomSheetContent}</div>
        )}
        {lastGuessedWordObject?.finish_stat && (
          <>
            <Modal visible={isModalVisible} onClose={() => setIsModalVisible(false)} countdowntTimer={<CountdownTimer />}>
              <Confetti width={confettiWidth} height={300} recycle={false} tweenDuration={100} />
              <ModalContent
                attemptsCount={lastGuessedWordObject?.finish_stat?.try_count}
                percentage={Math.round(lastGuessedWordObject?.finish_stat?.faster * 100)}
                shareText={`Я отгадал слово за ${lastGuessedWordObject?.finish_stat?.try_count} ${declareNumber(
                  lastGuessedWordObject?.finish_stat?.try_count,
                  [`попытку`, `попытки`, `попыток`],
                )} ${room ? `в комнате “${room?.name}” 😁` : ``}\nДавай помогай! ${window.location.href}`}
                modalRef={modalRef}
              />
            </Modal>
          </>
        )}
        {roomGuesses && (
          <Modal visible={isAltModalVisible} onClose={() => setIsAltModalVisible(false)}>
            <ModalContent
              rocket
              attemptsCount={roomGuesses?.length}
              shareText={`Я уже сделал ${roomGuesses?.length} ${declareNumber(roomGuesses?.length, [`попытку`, `попытки`, `попыток`])} ${
                room ? `в комнате “${room?.name}” 😎` : ``
              }\nДавай помогай! ${window.location.href}`}
            />
          </Modal>
        )}

        <div className={styles['support-footer']}>
          <p>
            Обсуждение слова и подсказки в{' '}
            <a href="https://t.me/guesswordcom" rel="noopener noreferrer" target="_blank">
              телеграм канале
            </a>{' '}
            :)
          </p>
        </div>
      </div>
    </>
  );
};
