import { Header } from 'shared/ui/header/ui';
import { useNavigate } from 'react-router-dom';
import { Path } from 'shared/config';
import { LoginForm } from 'widgets/login-form/ui';
import React, { useCallback, useState } from 'react';
import { postAuthLogin, postAuthUsers } from 'entities/auth/api';
import { useUserAuthContext } from 'features/user-auth-context/lib';

export const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const setIsAuthorized = useUserAuthContext((contextValue) => contextValue.setIsAuthorized);

  const handleSubmit = useCallback(
    async (username: string, password: string) => {
      setIsLoading(true);
      setError(undefined);
      try {
        postAuthUsers({ username, password })
          .then(async function () {
            await postAuthLogin({ username, password });
            if (setIsAuthorized) {
              setIsAuthorized(true);
            }
          })
          .catch(function (error) {
            if (error.response) {
              setError('Текущее имя уже занято. Попробуйте другое');
            } else {
              setError('Что-то пошло не так. Попробуйте другие данные.');
            }
          });
      } finally {
        setIsLoading(false);
      }
    },
    [setIsAuthorized],
  );

  return (
    <>
      <Header onBack={() => navigate(Path.Guess, { replace: true })} />
      <LoginForm
        title="Регистрация"
        submitText="Далее"
        suffixText="Уже зарегистрирован?"
        suffixLinkText="Войти"
        suffixLink={Path.Login}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};
