import React from 'react';
import { ReactComponent as Back } from '../../assets/back.svg';

import styles from './header.module.scss';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface HeaderProps {
  dark?: boolean;
  title?: string;
  onBack: () => void;
  background?: string;
  icon?: ReactNode;
}

export const Header = ({ title, onBack, background = `#6688CC`, icon, dark }: HeaderProps) => {
  return (
    <header className={styles.wrapper}>
      <Back onClick={onBack} className={classNames(styles.back, dark && styles['back-dark'])} />
      <h2 className={classNames(styles.title, dark && styles['title-dark'])}>{title}</h2>
      {icon}
      <span style={{ background }} className={classNames(styles.backdrop)} />
    </header>
  );
};
