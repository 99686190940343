export const routes = {
  authObtain: `/auth/jwt/create/`,
  authRefresh: `/auth/jwt/refresh/`,
  authLogin: `/auth/jwt/create/`,
  authUsers: `/auth/users/`,
  me: () => `/auth/users/me/`,
  roomGuesses: (slug: string) => `/rooms/${slug}/history/`,
  roomCreateGuess: (slug: string, language: string) => `/rooms/${slug}/guess/?language=${language}`,
  rooms: `/rooms/`,
  room: (slug: string) => `/rooms/${slug}/`,
  roomOwner: (slug: string) => `/rooms/${slug}/?include_word=true`,
};
