import { Header } from 'shared/ui/header/ui';
import { Path } from 'shared/config';
import { truncateString } from 'shared/lib';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { deleteRoom, useRoom, useRoomOwner } from 'entities/rooms/api';
import { Button } from 'shared/ui';

import styles from './room.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as EyeClosed } from '../../assets/eye-closed.svg';
import { ReactComponent as EyeOpen } from '../../assets/eye-open.svg';
import moment from 'moment';
import { ReactComponent as Share } from '../../assets/share.svg';
import { ReactComponent as Smile } from '../../assets/smile.svg';
import { Modal } from 'shared/ui/modal/ui/modal';
import copy from 'copy-to-clipboard';

export const Room = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string; name: string }>();
  const { data: room } = useRoomOwner(slug ? { slug } : null);
  const [, setDate] = useState(``);
  const { error: roomError } = useRoom({ slug: slug || `main` });
  const [isBlurred, setIsBlurred] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const percentage = Math.round(((room?.stat?.winner_count || 0) / (room?.stat?.games_count || 1)) * 100);

  useEffect(() => {
    if (roomError?.response?.status === 404) {
      navigate(Path.NotFound);
    }
  }, [navigate, roomError]);

  useEffect(() => {
    if (!room?.finish_at) {
      return;
    }

    const endDate = +new Date(room.finish_at);

    const timer = setInterval(() => {
      const diff = Math.abs(endDate - +new Date());
      setDate(moment(diff).utcOffset(0).format(`HH:mm:ss`));
    }, 1000);

    return () => clearInterval(timer);
  }, [room?.finish_at]);

  const handleCopy = useCallback(() => {
    copy(`Давай отгадывать слово вместе!\n${window.location.origin}${generatePath(Path.GuessWithSlug, { slug: slug as string })}`);
    setLinkCopied(true);
  }, [slug]);

  const recreateRoom = useCallback(async () => {
    deleteRoom(room?.slug || '');
    navigate(`${Path.RoomsNew}?slug=${room?.slug}&name=${room?.name}`);
  }, [navigate, room]);

  const handleDeleteRoom = useCallback(async () => {
    await deleteRoom(room?.slug || '');
    navigate(Path.RoomsList);
  }, [navigate, room?.slug]);

  const handleModal = () => {
    if (modalVisible) {
      setModalVisible(false);
    } else if (deleteModalVisible) setDeleteModalVisible(false);
  };

  return (
    <>
      <Header
        onBack={() => navigate(-1)}
        title={truncateString(room?.name, 15)}
        background="#6688CC"
        icon={<Share onClick={() => setModalVisible(true)} />}
      />
      <div className={styles.wrapper}>
        <p className={styles['players-count']}>{room?.stat?.games_count}</p>
        <p className={styles['players-count-label']}>Всего игроков</p>
        <div className={styles['guessed-sheet']}>
          <div className={styles['stats-row']}>
            <h5 className={styles['section-title']} style={{ paddingLeft: 0 }}>
              Отгадавших
            </h5>
            <p className={styles['stats-text']}>
              {room?.stat?.winner_count} из {room?.stat?.games_count}
            </p>
          </div>
          <div className={styles['progress-wrapper']}>
            <div className={styles['progress-path']} style={{ width: `${percentage}%` }}>
              {percentage}%
            </div>
          </div>
        </div>
        <h5 className={styles['section-title']}>Попытки</h5>
        <div className={styles.attempts}>
          <div className={styles.attempt}>
            <p className={styles['attempt-stat']}>{room?.stat?.min_games || `-`}</p>
            <p className={styles['attempt-label']}>Минимум</p>
          </div>
          <div className={styles.attempt}>
            <p className={styles['attempt-stat']}>{room?.stat?.mean_games || `-`}</p>
            <p className={styles['attempt-label']}>Среднее</p>
          </div>
          <div className={styles.attempt}>
            <p className={styles['attempt-stat']}>{room?.stat?.max_games || `-`}</p>
            <p className={styles['attempt-label']}>Максимум</p>
          </div>
        </div>
        {room?.stat?.word && (
          <div>
            <h5 className={styles['section-title']}>Загаданное слово</h5>
            <div className={styles['guessed-word']}>
              <p className={styles['guessed-word-text']} style={{ filter: isBlurred ? `blur(5px)` : undefined }}>
                {room?.stat?.word}
              </p>
              {isBlurred ? (
                <EyeClosed className={styles.icon} onClick={() => setIsBlurred(!isBlurred)} />
              ) : (
                <EyeOpen className={styles.icon} onClick={() => setIsBlurred(!isBlurred)} />
              )}
            </div>
          </div>
        )}

        {/* <p className={styles.timer}>До конца игры осталось {date || "00:00:00"}</p> */}

        {room?.stat?.word && room?.is_custom && (
          <Button className={styles.button} onClick={recreateRoom}>
            Пересоздать игру
          </Button>
        )}

        <Button className={styles.button} onClick={() => slug && navigate(generatePath(Path.GuessWithSlug, { slug }))}>
          Играть!
        </Button>
        <Button
          onClick={() => setDeleteModalVisible(true)}
          className={styles['delete-button']}
          textColor="#FB5157"
          backgroundColor="transparent"
        >
          Удалить комнату
        </Button>
      </div>
      <Modal visible={modalVisible || deleteModalVisible} onClose={handleModal}>
        {deleteModalVisible && (
          <div className={styles.modal}>
            <h2>Удалить комнату "{truncateString(room?.name, 10)}"?</h2>
            <div className={styles['button-group']}>
              <Button onClick={handleDeleteRoom}>Да</Button>
              <Button onClick={handleModal} backgroundColor="var(--secondary-color)">
                Отмена
              </Button>
            </div>
          </div>
        )}
        {modalVisible && (
          <div className={styles['modal-content']}>
            <Smile />
            <p className={styles['modal-title']}>Поделиться с друзьями</p>
            <p className={styles['modal-text']}>Ссылка на комнату:</p>
            <p className={styles['modal-text']}>
              {window.location.origin}
              {generatePath(Path.GuessWithSlug, { slug: slug as string })}
            </p>
            <Button onClick={handleCopy} className={styles.button}>
              Скопировать приглашение
            </Button>
            <p className={styles['modal-text']} style={{ color: linkCopied ? undefined : `transparent` }}>
              Пришлашение скопировано!
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};
