import { ReactComponent as Trophy } from '../../assets/trophy.svg';
import { ReactComponent as ThumbsUp } from '../../assets/thumbsUp.svg';
import { ReactComponent as ClappingHands } from '../../assets/clappingHands.svg';
import { ReactComponent as Rocket } from '../../assets/rocket.svg';
import copy from 'copy-to-clipboard';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './modal-content.module.scss';
import { Button } from 'shared/ui';
import { useShare } from 'pages/guess/lib';
import React, { Ref, useCallback } from 'react';

interface ModalContentProps {
  rocket?: boolean;
  attemptsCount: number;
  percentage?: number;
  shareText?: string;
  modalRef?: Ref<HTMLDivElement>;
}

const declareNumber = (number: number, words: [string, string, string]) => {
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]];
};

const renderResultInfo = (attemptsCount: number, percentage: number) => {
  return (
    <>
      <p className={styles.description}>
        Ваш результат:{' '}
        <span className={styles['blue-text']}>
          {attemptsCount} {declareNumber(attemptsCount, [`попытка`, `попытки`, `попыток`])}
        </span>
      </p>
      <p className={styles.stats}>
        Вы отгадали быстрее, чем <span className={styles['blue-text']}>{percentage}%</span> игроков!
      </p>
    </>
  );
};

const Content = ({ attemptsCount, percentage }: ModalContentProps) => {
  const renderContent = (icon: React.ReactNode, message: string) => (
    <>
      {icon}
      <p className={styles.message}>{message}</p>
      {renderResultInfo(attemptsCount, percentage ?? 0)}
    </>
  );

  let content;
  if (percentage && percentage >= 85) {
    content = renderContent(<Trophy />, 'Ну ты крутой!');
  } else if (percentage && percentage >= 50) {
    content = renderContent(<ClappingHands />, 'Поздравляю!');
  } else {
    content = renderContent(<ThumbsUp />, 'Молодец!');
  }

  return content;
};

export const ModalContent = ({ attemptsCount, percentage, shareText, rocket, modalRef }: ModalContentProps) => {
  const { isAvailable, handleShare } = useShare();

  const handleClick = useCallback(() => {
    if (isAvailable) {
      handleShare({ text: shareText });
    } else {
      shareText && copy(shareText);
      toast('Скопировали ссылку! Отправь своим друзьям!');
    }
  }, [handleShare, isAvailable, shareText]);

  return (
    <div className={styles.wrapper} ref={modalRef}>
      {rocket ? <Rocket /> : percentage && <Content attemptsCount={attemptsCount} percentage={percentage} />}

      {!isAvailable && <ToastContainer />}

      <Button className={styles['modal-button']} onClick={handleClick}>
        {isAvailable ? `Поделиться` : `Скопировать приглашение`}
      </Button>
    </div>
  );
};
