import React, { Dispatch, SetStateAction, useState } from 'react';
import { ReactComponent as EyeClosed } from '../../assets/eye-closed.svg';
import { ReactComponent as EyeOpened } from '../../assets/eye-open.svg';
import styles from './input.module.scss';
import classNames from 'classnames';

interface InputProps {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  password?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  isInvalid?: boolean;
}

export const Input = ({
  value,
  password,
  onChange,
  className,
  placeholder,
  onKeyDown,
  disabled = false,
  isInvalid = false,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <input
        placeholder={placeholder}
        className={styles.input}
        type={password && !showPassword ? `password` : `text`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={onKeyDown}
        disabled={disabled}
        style={{
          borderColor: isInvalid ? 'red' : '#3e3e3e',
        }}
      />
      {password &&
        (showPassword ? (
          <EyeOpened className={styles.icon} onClick={() => setShowPassword(!showPassword)} />
        ) : (
          <EyeClosed className={styles.icon} onClick={() => setShowPassword(!showPassword)} />
        ))}
    </div>
  );
};
