import React from 'react';
import { Outlet, useNavigate, useParams, generatePath } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import Dropdown from 'react-dropdown';

import { Header } from '../header';
import { ReactComponent as BoostryIcon } from '../../assets/boosty.svg';
import { ReactComponent as Cross } from 'shared/ui/modal/assets/cross.svg';
import { ReactComponent as OldVersionIcon } from '../../assets/old-version.svg';
import { ReactComponent as PatreonIcon } from '../../assets/patreon.svg';
import { ReactComponent as QuestionIcon } from '../../assets/question.svg';
import { ReactComponent as TelegramIcon } from '../../assets/tg-icon.svg';
import { ReactComponent as ExitIcon } from '../../assets/exit.svg';
import { ReactComponent as RoomsListIcon } from '../../assets/rooms.svg';
import { ReactComponent as RoomsNewIcon } from '../../assets/rooms-new.svg';
import { ReactComponent as ChartsIcon } from '../../assets/charts.svg';

import styles from './base-layout.module.scss';
import 'react-dropdown/style.css';

import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';
import { useRoom, useUser } from 'entities/rooms/api';
import { Button } from 'shared/ui';
import { Path } from 'shared/config';

import { useCallback } from 'react';
import { api } from 'shared/api';
import { useUserAuth, useUserAuthContext } from 'features/user-auth-context/lib';
import { useIsDesktop } from 'shared/lib/use-is-desktop';

export const BaseLayout = () => {
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [isBlurred, setIsBlurred] = useState(false);

  const [onShareClick, setOnShareClick] = useState<() => void>(() => undefined);

  const { slug } = useParams<{ slug: string }>();
  const { data: room, isValidating } = useRoom({ slug: slug || `main` });
  const { data: user } = useUser();
  const navigate = useNavigate();
  const { isAuthorized } = useUserAuth();
  const { matches: isDesktop } = useIsDesktop();
  const [ language, setLanuage ] = useState<string>(localStorage.getItem("language") || "RU");

  const handleHelp = () => {
    setIsSidebarOpen(false);
    setIsHelpVisible(true);
  };

  const setIsAuthorized = useUserAuthContext((contextValue) => contextValue.setIsAuthorized);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    delete api.defaults.headers.common.Authorization;

    if (setIsAuthorized) {
      setIsAuthorized(false);
    }

    window.location.reload();
  }, [setIsAuthorized]);

  function setLanguage(event: any) {
    setLanuage(event.value)
    localStorage.setItem("language", event.value)
  }

  const sideBarContent = (
    <OutsideClickHandler onOutsideClick={() => setIsSidebarOpen(false)}>
      <div style={{ position: 'relative' }}>
        <p onClick={() => setIsSidebarOpen(false)} className={styles['sidebar-cross']}>
          <Cross />
        </p>

        <div className={styles['sidebar-option']} style={{ paddingTop: '32px' }}>
          <QuestionIcon /> <span onClick={handleHelp}>Как играть?</span>
        </div>

        <a className={styles['sidebar-option']} target="_blank" href="https://t.me/guesswordcom" rel="noreferrer">
          <TelegramIcon fill="black" /> <span>Наш канал в Telegram</span>
        </a>

        <div className={styles['navbar-section']}>
          {!isAuthorized ? (
            <>
              Зарегистрируйся и получи доступ к статистике, создавай свои комнаты и играй с друзьями!
              <div className={styles['profile-auth']}>
                <Button onClick={() => navigate(Path.Register)}>Давай!</Button>
                <Button onClick={() => navigate(Path.Login)} textColor="black" backgroundColor="white">
                  Вход
                </Button>
              </div>
            </>
          ) : (
            <>
              <p style={{ color: 'gray' }}>{user?.username}</p>
              <div className={styles['sidebar-option']}>
                <ChartsIcon fill="black" />{' '}
                <span
                  onClick={() =>
                    navigate(
                      generatePath(Path.Room, {
                        slug: slug || ('main' as string),
                      }),
                    )
                  }
                >
                  Статистика игры
                </span>
              </div>
              <div className={styles['sidebar-option']}>
                <RoomsListIcon fill="none" /> <span onClick={() => navigate(Path.RoomsList)}>Мои комнаты</span>
              </div>
              <div className={styles['sidebar-option']}>
                <RoomsNewIcon fill="none" /> <span onClick={() => navigate(Path.RoomsNew)}>Создать комнату</span>
              </div>
              <div style={{marginTop: "20px"}}>
                <Dropdown 
                  className=""
                  options={[{value: "EN", label: "Английский язык"}, {value: "RU", label: "Русский язык"}, {value: "YK", label: "Якутский язык"}]} 
                  onChange={setLanguage} 
                  value={language}/>
              </div>
            </>
          )}
        </div>

        <div className={styles['navbar-section']}>
          <a className={styles['sidebar-option']} target="_blank" href="https://boosty.to/guess-word/about" rel="noreferrer">
            <BoostryIcon /> <span>Поддержать в Boosty</span>
          </a>
          <a className={styles['sidebar-option']} target="_blank" href="https://www.patreon.com/guessword" rel="noreferrer">
            <PatreonIcon /> <span>Поддержать в Patreon</span>
          </a>
          <a className={styles['sidebar-option']} href="https://old.guess-word.com/">
            <OldVersionIcon /> <span>Версия игры 1.0</span>
          </a>

          {isAuthorized && (
            <div className={styles['sidebar-option']}>
              <ExitIcon /> <span onClick={handleLogout}>Выйти из профиля</span>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );

  return (
    <Sidebar
      sidebar={sideBarContent}
      open={isSidebarOpen}
      styles={{
        sidebar: {
          background: 'var(--sheet-color)',
          width: '300px',
          padding: '4px 16px 0',
          zIndex: '10',
        },
      }}
    >
      <div className={styles.wrapper}>
        <Header
          onEyeClick={() => setIsBlurred(!isBlurred)}
          onMenuClick={() => setIsSidebarOpen(true)}
          onShareClick={onShareClick}
          background={isDesktop ? 'none' : isValidating || !room ? `#6688cc` : room?.theme}
          attemptCount={attemptCount}
        />
        <Outlet
          context={{
            setOnShareClick,
            isBlurred,
            isHelpVisible,
            setIsHelpVisible,
            setAttemptCount,
            language,
          }}
        />
      </div>
    </Sidebar>
  );
};
